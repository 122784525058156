<template>
  <div class="l-amap-container">
    <div class="l-amap" :id="mapId"></div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
var map = null
var imageLayer = null
var polygonDict = {}
var labelDict = {}
var polygonStyles = {
  1: {
    text: '低风险(蓝)',
    strokeColor: 'rgb(50,182,255)',
    fillColor: 'rgb(50,182,255)'
  },
  2: {
    text: '一般风险(黄)',
    strokeColor: 'rgb(254,255,3)',
    fillColor: 'rgb(254,255,3)'
  },
  3: {
    text: '较大风险(橙)',
    strokeColor: 'rgb(255,190,0)',
    fillColor: 'rgb(255,190,0)'
  },
  4: {
    text: '重大风险(红)',
    strokeColor: 'rgb(254,0,2)',
    fillColor: 'rgb(254,0,2)'
  }
}
export default {
  name: 'LAmapAssetArea',
  props: {},
  data() {
    return {
      mapId: 'map' + parseInt(Math.random() * 1000),
      defaultSetting: {
        center: [118, 32],
        zoom: 10
      }
    }
  },
  created() {
    map = null
    imageLayer = null
  },
  mounted() {
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.ControlBar', 'AMap.ToolBar', 'AMap.MouseTool', 'AMap.PolygonEditor', 'AMap.RectangleEditor'] // 插件列表
    })
      .then(AMap => {
        this.initMap()
      })
      .catch(e => {
        console.log(e)
      })
  },
  destroyed(a) {
    if (map) {
      map.destroy()
      map = null
    }
  },
  methods: {
    getMap() {
      return map
    },
    async initMap() {
      var gridImg = await import('@/assets/image/map/grid.jpg')
      var gridLayer = new AMap.TileLayer({
        zIndex: 2,
        getTileUrl: function(x, y, z) {
          return gridImg.default
        }
      })

      map = new AMap.Map(this.mapId, {
        center: this.defaultSetting.center,
        zoom: this.defaultSetting.zoom,
        layers: [gridLayer]
      })

      map.on('click', evt => {
        console.log(evt.pixel, evt.lnglat)
      })
    },
    resetZoomAndCenter() {
      map.setZoomAndCenter(this.defaultSetting.zoom, this.defaultSetting.center)
    },
    setCenter(center) {
      map.setCenter(center)
    },
    sceneClearAll() {
      polygonDict = {}
      labelDict = {}
      if (imageLayer) {
        map.removeLayer(imageLayer)
        imageLayer = null
      }
      map.clearMap()
    },
    sceneInit(sceneData) {
      // this.resetZoomAndCenter()
      if (imageLayer) {
        map.removeLayer(imageLayer)
        imageLayer = null
      }
      polygonDict = {}
      map.clearMap()

      imageLayer = new AMap.ImageLayer({
        bounds: new AMap.Bounds(sceneData._lnglats[0], sceneData._lnglats[1]),
        zooms: [8, 15],
        url: sceneData.imgUrl, // 图片 Url
        zIndex: 2
      })
      map.addLayer(imageLayer)
      sceneData.children.forEach(a => {
        if (a.ready) {
          if (a._lnglats) {
            var polygon = new AMap.Polygon(
              Object.assign(
                {
                  path: a._lnglats,
                  height: 10000
                },
                polygonStyles[a.safelyLevel ?? 1]
              )
            )
            map.add(polygon)
            polygonDict[a.areaId] = polygon
          }
          if (a._center) {
            var text = new AMap.Text({
              position: a._center,
              anchor: 'center',
              text: '<p style="color:rgb(20,87,126)">' + a.areaCode + '</p>' + '<p>' + a.areaName + '</p>' + '<p style="color:green">' + a.userName + '</p>',
              style: { 'font-size': '10px', 'text-align': 'center' }
            })
            map.add(text)
            labelDict[a.areaId] = text
          }
        }
      })
    },
    getPolygon(areaId) {
      return polygonDict[areaId]
    },
    getLabel(areaId) {
      return labelDict[areaId]
    },
    getImgLayerBounds(imgWidth, imgHeight) {
      this.resetZoomAndCenter()
      var mapEl = document.getElementById(this.mapId)
      var clientWidth = mapEl.clientWidth
      var clientHeight = mapEl.clientHeight
      var h = 600
      var w = parseInt((imgWidth * h) / imgHeight)
      var pix1 = [(clientWidth - w) / 2, clientHeight - (clientHeight - h) / 2]
      var pix2 = [(clientWidth - w) / 2 + w, (clientHeight - h) / 2]
      var p1 = map.containerToLngLat(pix1)
      var p2 = map.containerToLngLat(pix2)
      return [p1, p2]
    },
    drawPolygon(areaId) {
      areaId = areaId.toString()
      var polygon = null
      var label = null
      var defaultPath = null
      var defaultPos = null
      var adsorbs = []
      for (var a1 in polygonDict) {
        if (a1 === areaId) {
          polygon = polygonDict[a1]
        } else {
          adsorbs.push(polygonDict[a1])
        }
      }
      for (var a2 in labelDict) {
        if (a2 === areaId) {
          label = labelDict[a2]
        }
      }
      if (polygon) defaultPath = polygon.getPath()
      if (label) defaultPos = label.getPosition()
      var editor = new AMap.PolygonEditor(map, polygon)
      editor.setAdsorbPolygons(adsorbs)
      editor.open()
      editor.on('end', data => {
        if (data && data.target && !polygon) {
          data.target.destroy()
        }
      })

      var handleMoveLabel = data => {
        if (data && data.target && label) {
          label.setPosition(data.target.getBounds().getCenter())
        }
      }
      editor.on('addnode', handleMoveLabel)
      editor.on('removenode', handleMoveLabel)
      editor.on('adjust', handleMoveLabel)
      editor.on('move', handleMoveLabel)

      return {
        editor: editor,
        reset() {
          if (defaultPath) polygon.setPath(defaultPath)
          if (defaultPos) label.setPosition(defaultPos)
        },
        close() {
          editor.close()
          editor = null
        },
        getResult() {
          var target = editor.getTarget()
          return {
            target: target,
            center: target.getBounds().getCenter()
          }
        }
      }
    },
    drawPolygon121212(polygon, label) {
      var defaultPath = null
      var defaultPos = null
      if (polygon) defaultPath = polygon.getPath()
      if (label) defaultPos = label.getPosition()
      var editor = new AMap.PolygonEditor(map, polygon)
      editor.open()
      editor.on('end', data => {
        if (data && data.target && !polygon) {
          data.target.destroy()
        }
      })

      var handleMoveLabel = data => {
        if (data && data.target && label) {
          label.setPosition(data.target.getBounds().getCenter())
        }
      }
      editor.on('addnode', handleMoveLabel)
      editor.on('removenode', handleMoveLabel)
      editor.on('adjust', handleMoveLabel)
      editor.on('move', handleMoveLabel)

      return {
        editor: editor,
        reset() {
          if (defaultPath) polygon.setPath(defaultPath)
          if (defaultPos) label.setPosition(defaultPos)
        },
        close() {
          editor.close()
          editor = null
        },
        getResult() {
          var target = editor.getTarget()
          return {
            target: target,
            center: target.getBounds().getCenter()
          }
        }
      }
    },
    drawPolygon2222() {
      var polygon = new AMap.Polygon({
        path: [
          [117.732208, 32.232627],
          [118.145569, 32.162901],
          [118.013733, 31.875301],
          [117.655304, 31.868304],
          [117.594879, 32.044245]
        ]
      })
      map.add(polygon)
      setTimeout(() => {
        var editor = new AMap.PolygonEditor(map, polygon)
        editor.open()
        setInterval(() => {
          console.log(
            editor
              .getTarget()
              .getPath()
              .map(a => [a.lng, a.lat])
              .join(',')
          )
          console.log(
            polygon
              .getPath()
              .map(a => [a.lng, a.lat])
              .join(',')
          )
        }, 2000)
      }, 5000)
    }
  },
  watch: {}
}
</script>
<style lang="less" scoped>
.l-amap-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.l-amap {
  height: 100%;
  width: 100%;
  resize: both;
  cursor: default;
  background-color: rgb(244, 244, 244);
}
</style>
