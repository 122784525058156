<template>
  <div style="width:100%;height:100%;position:relative;">
    <l-amap-assetarea ref="map"></l-amap-assetarea>
    <l-absolute :width="'100%'" :left="15" :right="15" anchor="left top">
      <l-upload v-if="sceneInfo" @success="sceneImgUploadSuccess" url="api/enterprise/easset/area/layer_img_uplload">
        <el-button type="" size="small" icon="el-icon-picture">设置场景底图</el-button>
      </l-upload>
      <el-button class="margin-left" v-if="sceneReady && areaInfo && areaInfo.ready == false" type="primary" size="small" icon="el-icon-picture" @click="areaDrawAdd"
        >绘制区域【{{ areaInfo.areaCode }}-{{ areaInfo.areaName }}】</el-button
      >
      <el-button class="margin-left" v-if="sceneReady && areaInfo && areaInfo.ready == true" type="primary" size="small" icon="el-icon-picture" @click="areaDrawEdit"
        >修改区域【{{ areaInfo.areaCode }}-{{ areaInfo.areaName }}】</el-button
      >
      <el-button class="margin-left" v-if="sceneReady && areaInfo && areaEditing" type="success" size="small" icon="el-icon-picture" @click="areaDrawSave(!areaInfo.ready)">保存</el-button>
      <el-button class="margin-left" v-if="sceneReady && areaInfo && areaEditing" type="danger" size="small" icon="el-icon-picture" @click="areaDrawCancle(!areaInfo.ready)">取消</el-button>
    </l-absolute>
  </div>
</template>

<script>
import lAmapAssetarea from '@/views/components/l-amap-assetarea.vue'
var drawEditor = null
export default {
  components: { 'l-amap-assetarea': lAmapAssetarea },
  props: {
    monitorData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sceneReady: false,
      areaEditing: false,
      sceneId: null,
      sceneInfo: null,
      areaId: null,
      areaInfo: null,
      areaPolygon: null,
      areaLabel: null
    }
  },
  mounted() {},
  methods: {
    async getSceneAreaDetail(sceneId) {
      return await this.$lgh.get('api/enterprise/easset/area/scene/detail', { sceneAreaId: sceneId })
    },
    getAreaInfo(areaId) {
      var r = null
      this.sceneInfo.children.forEach(a => {
        if (a.areaId === areaId) r = a
      })
      return r
    },
    clearScene() {
      this.$refs.map.sceneClearAll()
      this.sceneReady = false
      this.sceneId = null
      this.sceneInfo = null
      this.areaId = null
      this.areaInfo = null
      this.areaPolygon = null
      this.areaLabel = null
      this.areaDrawEditorClose()
    },
    refreshScene() {
      this.viewScene(this.sceneId, this.areaId)
    },
    async viewScene(sceneId, areaId) {
      var r = await this.getSceneAreaDetail(sceneId)
      this.clearScene()
      this.sceneInfo = r
      this.sceneId = sceneId
      this.initScene()
      if (areaId) {
        this.areaId = areaId
        this.areaInfo = this.getAreaInfo(areaId)
        this.areaPolygon = this.$refs.map.getPolygon(areaId)
        this.areaLabel = this.$refs.map.getLabel(areaId)
        this.initArea()
      }
    },
    initScene() {
      if (this.sceneInfo.ready === false) {
        this.sceneReady = false
        this.$lgh.toastError('请设置场景图片')
      } else {
        this.$refs.map.sceneInit(this.sceneInfo)
        this.sceneReady = true
      }
    },
    initArea() {
      this.$refs.map.setCenter(this.areaInfo._center)
    },
    areaDrawAdd() {
      this.$lgh.notifySuccess('绘制开始', '双击绘制的多边形保存数据')
      drawEditor = this.$refs.map.drawPolygon(this.areaId)
      this.areaEditing = true
    },
    areaDrawEdit() {
      this.$lgh.notifySuccess('绘制开始', '双击绘制的多边形保存数据')
      drawEditor = this.$refs.map.drawPolygon(this.areaId)
      this.areaEditing = true
    },
    areaDrawSave(isAdd) {
      var result = drawEditor.getResult()
      if (result) {
        this.updateArea(
          {
            areaId: this.areaId,
            enterpriseCode: this.sceneInfo.enterpriseCode,
            lnglats: result.target.toString(),
            center: result.center.toString()
          },
          () => {
            this.areaDrawEditorClose()
            if (isAdd) {
              this.refreshScene()
            } else {
            }
          }
        )
      }
    },
    areaDrawCancle(isAdd) {
      this.areaEditing = false
      if (drawEditor) {
        drawEditor.reset()
        drawEditor.close()
      }
    },
    areaDrawEditorClose() {
      if (drawEditor) {
        drawEditor.close()
        drawEditor = null
        this.areaEditing = false
      }
    },
    sceneImgUploadSuccess(res) {
      var data = {
        enterpriseCode: this.sceneInfo.enterpriseCode,
        areaId: this.sceneId,
        imgUrl: res.url,
        imgWidth: res.width,
        imgHeight: res.height
      }
      var bounds = this.$refs.map.getImgLayerBounds(data.imgWidth / 2, data.imgHeight / 2)
      var x1 = bounds[0].lng
      var y1 = bounds[0].lat
      var x2 = bounds[1].lng
      var y2 = bounds[1].lat
      data.lnglats = x1 + ',' + y1 + ';' + x2 + ',' + y2
      data.center = ((x1 + x2) / 2).toFixed(6) + ',' + ((y1 + y2) / 2).toFixed(6)
      this.updateSceneImage(data)
    },
    updateSceneImage(data) {
      this.$lgh.post('api/enterprise/easset/area/scene/image/update', data).then(res => {
        this.parentTreeRefresh()
        this.$lgh.toastSuccess('场景底图更新成功')
        this.refreshScene()
      })
    },
    updateArea(data, cb) {
      this.$lgh.post('api/enterprise/easset/area/lnglat/update', data).then(res => {
        this.parentTreeRefresh()
        cb && cb()
        this.$lgh.toastSuccess('区域坐标信息更新成功')
      })
    },
    _getPolygonCenter(lnglatarr) {
      var total = lnglatarr.length
      var X = 0
      var Y = 0
      var Z = 0
      lnglatarr.map(item => {
        var lng = (item.lng * Math.PI) / 180
        var lat = (item.lat * Math.PI) / 180
        var x, y, z
        x = Math.cos(lat) * Math.cos(lng)
        y = Math.cos(lat) * Math.sin(lng)
        z = Math.sin(lat)
        X += x
        Y += y
        Z += z
      })
      X = X / total
      Y = Y / total
      Z = Z / total
      var Lng = Math.atan2(Y, X)
      var Hyp = Math.sqrt(X * X + Y * Y)
      var Lat = Math.atan2(Z, Hyp)
      return new AMap.LngLat((Lng * 180) / Math.PI, (Lat * 180) / Math.PI)
    },
    parentTreeRefresh() {
      this.$parent.refreshTree()
    }
  }
}
</script>

<style lang="less" scoped></style>
