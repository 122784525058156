<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="padding twice" style="padding-bottom:0">
        <el-form-item label="节点类型" prop="areaType">
          <div class="flex">
            <div>
              <el-radio-group v-model="dataInfo.areaType" :disabled="dataInfo.areaId">
                <el-radio-button v-for="(item, index) in typeList" :key="index" :label="item.code">{{ item.text }}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上级节点" prop="parentId">
          <el-cascader v-model="dataInfo.parentIdArr" :props="areaProps" :options="areaTreeData" :disabled="dataInfo.areaId"></el-cascader>
        </el-form-item>
        <el-form-item label="节点名称" prop="areaName">
          <el-input v-model="dataInfo.areaName" autocomplete="off" placeholder="请输入节点名称"></el-input>
        </el-form-item>
        <el-form-item label="区域编号" prop="areaCode" v-if="dataInfo.areaType == 2">
          <el-input v-model="dataInfo.areaCode" autocomplete="off" placeholder="请输入区域编号"></el-input>
        </el-form-item>
        <el-form-item label="安全风险等级" prop="safelyLevel" v-if="dataInfo.areaType == 2" style="width:100%">
          <el-radio-group v-model="dataInfo.safelyLevel">
            <el-radio-button v-for="(item, index) in safelyLevelList" :key="index" :label="item.code">{{ item.text }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="责任部门" prop="departId" v-if="dataInfo.areaType == 2">
          <l-select v-model="dataInfo.departId" :remote="$api.departList" :all="false" all-label="--请选择--" @change="departChange"></l-select>
        </el-form-item>
        <el-form-item label="责任人" prop="userId" v-if="dataInfo.areaType == 2">
          <l-select-employee v-model="dataInfo.userName" :departId="dataInfo.departId" code="user" @change="userChange"></l-select-employee>
        </el-form-item>
        <el-form-item label="实施人" prop="userId" v-if="dataInfo.areaType == 2">
          <l-select-employee v-model="dataInfo.effectUserName" :departId="dataInfo.departId" code="effectUser" @change="userChange"></l-select-employee>
        </el-form-item>
        <el-form-item label="委托人" prop="userId" v-if="dataInfo.areaType == 2">
          <l-select-employee v-model="dataInfo.trustUserName" :departId="dataInfo.departId" code="trustUser" @change="userChange"></l-select-employee>
        </el-form-item>
        <el-form-item label="排序号" prop="sort">
          <el-input v-model="dataInfo.sort" autocomplete="off" style="width:100px;"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div class="fl text-gray text-mini">
        <p v-if="dataInfo.areaType == 0">
          【分组节点】仅仅为了进行数据分组,无其他业务含义,上级节点可以是<span class="text-orange padding-lr-mini">[无上级]</span>,也可以是
          <span class="text-orange padding-lr-mini">分组节点</span>
        </p>
        <p v-else-if="dataInfo.areaType == 1">
          【场景节点】需要设置场景底图,上级节点可以是<span class="text-orange padding-lr-mini">[无上级]</span>,也可以是 <span class="text-orange padding-lr-mini">分组节点</span>
        </p>
        <p v-else-if="dataInfo.areaType == 2">【区域节点】可以绘制多边形区域,上级节点只能是 <span class="text-orange padding-lr-mini">场景节点</span></p>
      </div>
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" v-if="!dataInfo.areaId" @click="save(true)">保存并继续</el-button>
      <el-button type="success" @click="save(false)">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import lSelectEmployee from '@/views/components/l-select-employee.vue'
export default {
  components: { 'l-select-employee': lSelectEmployee },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      areaTreeData: null,
      areaProps: {
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'areaId',
        label: 'areaName'
      },
      typeTag: null,
      typeList: [
        { code: 0, text: '分组' },
        { code: 1, text: '场景' },
        { code: 2, text: '区域' }
      ],
      safelyLevelList: [
        { code: 1, text: '低风险' },
        { code: 2, text: '一般风险' },
        { code: 3, text: '较大风险' },
        { code: 4, text: '重大风险' }
      ],
      dataRules: {
        areaName: [{ required: true, trigger: 'blur', message: '名称不能为空' }],
        areaCode: [{ required: true, trigger: 'blur', message: '区域编号不能为空' }],
        safelyLevel: [{ required: true, trigger: 'blur', message: '安全风险等级不能为空' }],
        departId: [{ required: true, trigger: 'blur', message: '责任部门不能为空' }],
        userId: [{ required: true, trigger: 'blur', message: '责任人不能为空' }]
      }
    }
  },
  mounted() {
    this.getAreaTree()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    getAreaTree() {
      this.$lgh.get('api/enterprise/easset/area/tree', ['enterpriseCode'], false).then(res => {
        res.unshift({
          areaId: -1,
          areaName: '[无上级]'
        })
        this.areaTreeData = res
      })
    },
    departChange() {
      this.dataInfo.userId = null
      this.dataInfo.userName = null
      this.dataInfo.effectUserId = null
      this.dataInfo.effectUserName = null
      this.dataInfo.trustUserId = null
      this.dataInfo.trustUserName = null
    },
    userChange(item, code) {
      this.dataInfo[code + 'Id'] = item.userId
      this.dataInfo[code + 'Name'] = item.name
    },
    save(next) {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          var r = await this.$lgh.post('api/enterprise/easset/area/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess &&
            this.onsuccess({
              data: r,
              next: next
            })
        }
      })
    }
  }
}
</script>

<style></style>
