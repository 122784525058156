<template>
  <section class="l-content full relative">
    <div class="area-container">
      <div class="area-list">
        <el-card class="sm" style="height:100%;" :body-style="bStyle">
          <p class="text-center">
            <el-button @click="addArea()" style="width:100%" icon="el-icon-circle-plus-outline">添加分组/区域</el-button>
          </p>
          <el-divider> </el-divider>
          <el-tree :data="areaTreeData" node-key="areaId" :props="defaultProps" highlight-current :default-expanded-keys="defaultExpandedKeys" :expand-on-click-node="false">
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <span class="flex-sub" :class="data.ready ? 'text-black' : 'text-grey'" @click="handleNodeClick(data, node)">
                <i class="el-icon-share" v-if="data.areaType == 0"></i>
                <i class="el-icon-picture" v-else-if="data.areaType == 1"></i>
                <i class="el-icon-rank" v-else-if="data.areaType == 2"></i>{{ node.label }}</span
              >
              <span>
                <el-button v-if="data.areaType == 0" type="text" size="small" icon="el-icon-circle-plus-outline" @click="addArea(data)"> </el-button>
                <el-button v-if="data.areaType == 1" type="text" size="small" icon="el-icon-circle-plus-outline" @click="addArea(data)"> </el-button>
                <el-button type="text" size="small" icon="el-icon-edit" @click="editArea(data)"></el-button>
                <el-button type="text" size="small" icon="el-icon-delete" @click="deleteArea(data)"> </el-button>
              </span>
            </div>
          </el-tree>
        </el-card>
      </div>
      <div class="area-map">
        <area-map ref="map"></area-map>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import areaMap from './area/map.vue'
import areaAddoredit from './area/_addoredit'
export default {
  computed: {
    ...mapState(['userInfo', 'setting'])
  },
  name: 'CarMonitor',
  components: { areaMap },
  data() {
    return {
      bStyle: { height: document.documentElement.clientHeight - 90 + 'px', overflow: 'auto' },
      areaTreeData: null,
      defaultProps: {
        children: 'children',
        label: 'areaName'
      },
      defaultExpandedKeys: []
    }
  },
  created() {
    this.getAreaTree()
  },
  methods: {
    async getAreaTree() {
      this.areaTreeData = await this.$lgh.get('api/enterprise/easset/area/tree', ['enterpriseCode'])
    },
    async refreshTree() {
      this.areaTreeData = await this.$lgh.get('api/enterprise/easset/area/tree', ['enterpriseCode'], false)
    },
    async addArea(data) {
      var result = await this.$lgh.openDialog({
        ins: areaAddoredit,
        title: '新增分组/区域',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            areaType: data && data.areaType === 1 ? 2 : 0,
            parentIdArr: data ? data.fullIdArr : [-1],
            departId: null,
            userId: null,
            userName: null,
            effectUserId: null,
            effectUserName: null,
            trustUserId: null,
            trustUserName: null,
            safelyLevel: 1,
            sort: 1
          }
        }
      })
      this.defaultExpandedKeys = [result.data.areaId]
      this.getAreaTree()
      if (result.next) {
        setTimeout(() => {
          this.addArea(data)
        }, 1000)
      }
    },
    async editArea(data) {
      var res = await this.$lgh.get('api/enterprise/easset/area/detail', { areaId: data.areaId })

      var result = await this.$lgh.openDialog({
        ins: areaAddoredit,
        title: '修改节点',
        data: {
          dataInfo: res
        }
      })
      this.defaultExpandedKeys = [result.data.areaId]
      this.getAreaTree()
    },
    deleteArea(data) {
      this.$lgh.msgConfirm('确定删除[' + data.areaName + ']?', () => {
        this.$lgh.get('api/enterprise/easset/area/delete', { areaId: data.areaId, enterpriseCode: data.enterpriseCode }).then(res => {
          this.defaultExpandedKeys = data.parentId ? [data.parentId] : []
          this.getAreaTree()
        })
      })
    },
    handleNodeClick(data, node) {
      this.defaultExpandedKeys = [data.areaId]
      if (data.areaType === 0) {
        node.expanded = true
      } else if (data.areaType === 1) {
        node.expanded = true
        this.$refs.map.viewScene(data.areaId)
      } else if (data.areaType === 2) {
        this.$refs.map.viewScene(data.parentId, data.areaId)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.area-container {
  display: flex;
  height: 100%;
  .area-list {
    width: 250px;
  }
  .area-map {
    flex: 1;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
